.wrapper{
  margin: 30px 0;
}

.control{
  padding: 15px 5px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.content{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: flex-start;
}

.card{
  width: 100%;
  max-width: 520px;
}

.button{
  margin-top: 8px!important;
}

.avatar-change{
  display: flex;
  justify-content: center;
}

.avatar-control{
  padding: 0 8px;
  display: flex;
  justify-content: flex-start;
}

.avatar-title{
  margin-right: auto;
  width: calc(100% - 180px);
  overflow-x: hidden;
}

.comment-header{
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-trash{
  position: absolute;
  right: -14px;
  top: -14px;
}

.comment-container{
  border-bottom: 1px solid #bdbdbd80;
}