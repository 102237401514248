.logo {
  font-size: 1.2rem!important;
 line-height: normal!important;
  background: linear-gradient(180deg, #0b2c61 33%, #1d79db 66%, #8AA9D6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title {
  padding: 0 20px;
  font-size: 1.5rem!important;
  text-align: right;
  line-height: normal!important;
  background: linear-gradient(180deg, #0b2c61 33%, #1d79db 66%, #8AA9D6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3{
  text-align: center;
}

.fullwidth{
  width: 100%;
}

.bar{
  display: flex;
  height: 54px;
  padding: 10px 15px;
  justify-content: flex-end;
  align-items: center;
}

.bar__title{
  display: flex;
  align-items: center;
}