.user-list + .user-list{
border-top: 1px solid #bdbdbd;
}

.user-list--pointer {
  cursor: pointer;
}

.page-content{
  padding: 10px;
  width: 100%;
}

.page-overflow{
  overflow-x: auto;
}

.center{
  display: flex;
  justify-content: center;
}

.page-content_500{
  max-width: 500px!important;
  min-width: 310px;
}

.error-text{
  padding-left: 20px;
}

.content-wrapper{
  background-color: #ffffffe0;
  border-radius: 20px;
  padding: 10px 20px;
}

.textSuccess {
  color: #2e7d32
}

.textError{
  color: #d32f2f
}

.short {
  width: calc(100% - 60px)
}