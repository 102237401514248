.main {
  display: flex;
  width: 100%;
  height: calc(100vh - 54px);
  padding: 0 10px 10px;
  justify-content: center;
  align-items: center;
}

.registration{
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 10px 10px;
}

.form {
  display: flex;
  width: 100%;
  max-width: 600px;
  flex-direction: column;
  padding: 50px 20px 20px;
  gap: 20px;
  background-color: #ffffffe0;
  border-radius: 20px;
  z-index: 2;
}

.main>.form{
  justify-content: center;
}
.input{
  height: 40px
}

.button{
  min-height: 56px
}