.table{
  display: flex;
  width: 100%;
}

.card{
  width: 100%;
  margin: 1rem 0;
  border-radius: 6px!important;
}

.column{
  display: flex;
  flex-direction: column;
}

h5.title {
  margin-left: 20px
}

.cell{
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.cell_left{
  justify-content: flex-start;
}

.cell__title{
  width: 160px;
}

.cell__price{
  margin-left: auto;
  padding: 0 20px;
}

.cell+.cell{
  border-top: 1px solid rgb(0 0 0 / 20%);
}

.coast{
  flex-grow: 1;
  min-width: 130px;
}

.note{
  margin-bottom: 1rem;
}

.fullWidth{
  width: 100%;
}