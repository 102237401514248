* {
 box-sizing: border-box;
 margin: 0;
 padding: 0;
}

.app-wrapper{
  max-width: 1100px;
  margin: 0 auto;
  overflow-x: hidden;
  z-index:  1;
}

.cover{
  position: fixed;
  height: 100vh;
  width: 100%;
  background:  center no-repeat url("static/bg.PNG");
  background-clip: border-box;
  background-size: cover;
  z-index:  -1;
}

.cover::before{
  content: "";
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: #ffffff30;
}