.calendar {
  width: 100%;
  padding: 15px 5px 5px;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 15px 5px;
  justify-content: center;
  max-width: 310px;
  margin: 0 auto;
}

.time {
  display: flex;
  flex-direction: column;
}

.timeString {
  display: flex;
  width: 100%;
  padding: 15px;
  gap: 10px;
}

.inputDate {
  font-size: 1.5rem !important;
  max-width: 160px;
}

.bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  padding: 8px 30px;
  display: flex;
  justify-content: center;
}

.button {
  margin: 0 auto;
  min-height: 56px;
  max-width: 315px !important;
}

.set {
  width: 100%;
  display: flex;
}

.set__info {
  padding-left: 20px;
}

.set__control {
  margin-top: 8px !important;
}

.set__apply {
  display: flex;
  align-items: center;
  gap: 10px;
}

.tab {
  background-color: #1976d220;
  color: #1976d2;
}

/* .tab.tab__selected {
  color: #fff;
  background: #1976d2;
} */
